import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const CommonContext = createContext()

const CommonProvider = (props) => {
  const [IP, setIP] = useState(null)
  const [variation, setVariation] = useState(false)
  const [variants, setVariants] = useState(null)
  const [variant, setVariant] = useState(null)
  const [options, setOptions] = useState(null)
  const [media, setMedia] = useState(null)
  const [filter, setFilter] = useState(null)

  const combineOptions = () => {
    const combine = (a1, a2) => {
      return a1.flatMap((u) => a2.map((v) => [u, v].flat()))
    }
    if (options) {
      const o = options.map((o) => o.values).filter((v) => v.length > 0)
      if (o.length > 0) {
        let combinations = o[0]
        for (let i = 1; i < o.length; i++) {
          combinations = combine(combinations, o[i])
        }
        return combinations
      }
    }
    return []
  }

  const fetchIP = async () => {
    const { data } = await axios.get('https://api.ipify.org/?format=json')
    setIP(data.ip)
  }

  useEffect(() => {
    setFilter({
      disjunctive: 'and',
      rules: [],
      sort: 'CREATED_DESC',
    })
    fetchIP()
  }, [])

  return (
    <CommonContext.Provider
      value={{
        IP,
        variation,
        variants,
        variant,
        options,
        media,
        filter,
        setVariation,
        setVariants,
        setVariant,
        setOptions,
        setMedia,
        setFilter,
        combineOptions,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  )
}

CommonProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { CommonProvider, CommonContext }
