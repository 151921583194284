import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import axios from 'axios'
import moment from 'moment'

const AuthContext = createContext()

const userPool = new CognitoUserPool({
  // UserPoolId: 'us-east-1_39GuWlvPo',
  // ClientId: '4o7qvb36mh7ac5f2gk1vh4feic',
  UserPoolId: 'us-east-1_efg13j97X',
  ClientId: '45b98qks1nm00tepfplm6o2g5u',
})

const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [profile, setProfile] = useState(null)
  const [username, setUsername] = useState(null)
  const [user, setUser] = useState(null)
  const [status, setStatus] = useState(null)
  const [deletedAt, setDeletedAt] = useState(null)

  const [notification, setNotification] = useState(null)

  const fetchData = async (username) => {
    try {
      const url = `${process.env.REACT_APP_API}/profile/id`
      const { data } = await axios.get(url, {
        params: { username: username },
      })
      if (Number(data.id) > 0) {
        setUser(data.id)
        setStatus(data.status)
        const date = moment(data.deleted_at).local().toString()
        setDeletedAt(date)
      } else {
        signOut()
      }
    } catch (error) {
      console.error('Error fetching: ', error)
      return
    }
  }

  const fetchNotification = async () => {
    try {
      const url = `${process.env.REACT_APP_API}/portal/unread`
      const { data } = await axios.get(url, {
        params: {
          user: user,
        },
      })
      setNotification({ unread: data.unread })
    } catch (error) {
      console.error('Error fetching: ', error)
      return
    }
  }

  const fetchUserAttributes = () => {
    const currentUser = userPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession(async (error, session) => {
        if (error) {
          console.log('GetSession::failure: ', error)
          setIsReady(true)
          return
        }

        currentUser.getUserAttributes((error, attributes) => {
          if (error) {
            console.log('GetUserAttributes::failure: ', error)
            setIsReady(true)
            return
          }
          const attr = {}
          for (const attribute of attributes) {
            const { Name, Value } = attribute
            attr[Name] = Value
          }

          setProfile({ ...attr, ...session })
          setIsAuthenticated(true)
          setIsReady(true)
        })
      })
    } else {
      setIsReady(true)
    }
  }

  const signUp = (username, password) => {
    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, [], null, (error, result) => {
        if (error) {
          console.log('SignUp::failure: ', error)
          resolve({ code: 'failure', data: error })
          return
        }
        resolve({ code: 'success', data: result })
      })
    })
  }

  const confirmRegistration = (username, code) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
      cognitoUser.confirmRegistration(code, true, (error, result) => {
        if (error) {
          console.log('ConfirmRegistration::failure: ', error)
          resolve({ code: 'failure', data: error })
          return
        }
        resolve({ code: 'success', data: result })
      })
    })
  }

  const resendConfirmationCode = (username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
      cognitoUser.resendConfirmationCode((error, result) => {
        if (error) {
          console.log('ResendConfirmationCode::failure: ', error)
          resolve({ code: 'failure', data: error })
          return
        }
        resolve({ code: 'success', data: result })
      })
    })
  }

  const signIn = (username, password) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
        // Storage: new CookieStorage({ secure: false, domain: process.env.REACT_APP_URL }),
      })
      //const cognitoUser = userPool.getCurrentUser()
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      })
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setProfile(data)
          setIsAuthenticated(true)
          resolve({ code: 'success', data: data })
        },
        onFailure: (data) => {
          resolve({ code: 'failure', data: data })
        },
        newPasswordRequired: (data) => {
          resolve({ code: 'failure', data: data })
        },
      })
    })
  }

  const changePassword = (username, oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: oldPassword,
      })
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
            if (error) {
              resolve({ code: 'failure', data: error })
            } else {
              resolve({ code: 'success', data: result })
            }
          })
        },
        onFailure: (data) => {
          resolve({ code: 'failure', data: data })
        },
      })
    })
  }

  const forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve({ code: 'success', data: data })
        },
        onFailure: (data) => {
          resolve({ code: 'failure', data: data })
        },
      })
    })
  }

  const confirmPassword = (username, code, password) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      })
      cognitoUser.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve({ code: 'success', data: data })
        },
        onFailure: (data) => {
          resolve({ code: 'failure', data: data })
        },
      })
    })
  }

  const signOut = () => {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser) {
      cognitoUser.signOut(() => setIsAuthenticated(false))
    }
  }

  useEffect(() => {
    if (profile) setUsername(profile.accessToken.payload.username)
  }, [profile])

  useEffect(() => {
    if (user) fetchNotification(user)
  }, [user])

  useEffect(() => {
    if (username) fetchData(username)
  }, [username])

  useEffect(() => {
    fetchUserAttributes()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isReady,
        profile,
        username,
        user,
        status,
        deletedAt,
        notification,
        fetchNotification,
        fetchUserAttributes,
        signUp,
        confirmRegistration,
        resendConfirmationCode,
        signIn,
        changePassword,
        forgotPassword,
        confirmPassword,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export { AuthProvider, AuthContext }
