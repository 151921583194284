import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { AuthProvider } from './contexts/AuthProvider'
import { CommonProvider } from './contexts/CommonProvider'

ReactDOM.render(
  <AuthProvider>
    <CommonProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CommonProvider>
  </AuthProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
