import React, { useEffect, useContext, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { AuthContext } from './contexts/AuthProvider'

const loading = <div className="pt-3 text-center"></div>

// Containers
const Layout = React.lazy(() => import('./components/layout/Layout'))

const Confirm = React.lazy(() => import('./pages/register/Confirm'))
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Portal = React.lazy(() => import('./pages/portal/Portal'))

// Pages
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = () => {
  const { isAuthenticated, isReady } = useContext(AuthContext)
  return isReady ? (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route
            exact
            path="/catalogs"
            name="Catalogs"
            element={isAuthenticated ? <Navigate replace to="/catalogs" /> : <Login />}
          /> */}
          <Route path="/confirmuser" name="Confirm User" element={<Confirm />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route
            exact
            path="/portal"
            name="Portal"
            element={isAuthenticated ? <Portal /> : <Login />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/home" name="Home" element={<Layout />} />
          <Route path="*" name="Home" element={<Layout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  ) : (
    <div className="min-vh-100 bg-light bg-gradient"></div>
  )
}

export default App
